@import "styles/main.scss";

.app-future {
  .app-items {
    margin-bottom: $space-8;

    @include responsive(normal) {
      margin-bottom: $space-10;
    }

    &--hide {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .app-items__title {
      font-size: 1.8rem;
      margin-bottom: $space-3;
    }

    .app-items__app {
      display: flex;
      align-items: center;
      gap: $space-6 $space-3;
      flex-direction: column;
      flex-wrap: wrap;

      @include responsive(tablet) {
        flex-direction: row;
      }

      .item-app {
        padding: $space-4 $space-6;
        border-radius: $radius-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $space-4;
        background-color: rgba($background-white, 0.4);
        width: 100%;
        cursor: pointer;
        transition: 0.3s all;
        color: #000;
        position: relative;

        @include responsive(tablet) {
          width: unset;
          justify-content: unset;
        }

        @include responsive(normal) {
          width: 18rem;

          &:hover {
            background-color: $text-primary;
            color: $text-white;
          }
        }

        &--coming {
          cursor: default;
          pointer-events: none;

          &:hover {
            background-color: rgba($background-white, 0.4);
            color: unset;
          }
        }

        .coming-soon {
          position: absolute;
          font-size: 1.2rem;
          top: -1rem;
          right: 1rem;
          padding: 0.2rem $space-2;
          border-radius: 999px;
          background-color: $btn-primary;
          color: $text-white;
        }

        .icon-app {
          width: 4rem;
        }
      }
    }
  }
}
