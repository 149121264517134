@import "styles/main.scss";

.headerWrapper {
  :global {
    .header-wrapper {
      width: 100%;
      transition: 0.5s all;
      padding: $space-4 0;
      position: fixed;
      z-index: 998;

      &--hide {
        top: -10rem;
      }

      &--show {
        top: 0;
      }

      &--background {
        background-color: $background-white;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
      }

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 $space-3;

        @include responsive(normal) {
          height: 5rem;
          padding: 0 $space-6;
        }

        // @include responsive(small) {
        //   padding: 0 $space-8;
        // }

        .header-left {
          .icon-logo {
            font-size: 3.2rem;

            @include responsive(normal) {
              font-size: 4rem;
            }
          }
        }

        .icon-menu {
          font-size: 2.4rem;

          @include responsive(normal) {
            display: none;
          }
        }

        .header-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          height: 101svh;
          top: -$space-5;
          left: 100%;
          background-color: $text-primary;
          padding: 0 $space-6;
          transition: 0.3s all;

          @include responsive(normal) {
            gap: $space-12;
          }

          &--show {
            left: 0;
          }

          @include responsive(normal) {
            padding: 0;
            position: unset;
            top: unset;
            left: unset;
            align-items: center;
            flex-direction: row;
            width: unset;
            height: unset;
            background-color: transparent;
          }

          .header-right-wrapper {
            display: flex;
            flex-direction: column;
            gap: $space-4;

            @include responsive(normal) {
              gap: $space-12;
              align-items: center;
              flex-direction: row;
            }

            .head-menu {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 2.4rem;
              color: $text-white;
              padding: $space-5 0;

              @include responsive(normal) {
                display: none;
              }

              .icon-logo-mobile {
                font-size: 3.2rem;

                @include responsive(normal) {
                  font-size: 4rem;
                }
              }
            }

            .menu-header-container {
              color: $text-grey;

              @include responsive(normal) {
                position: relative;
                z-index: 0;
                margin-bottom: 0;
              }

              &--show-menu {
                .menu-head {
                  .icon-down {
                    transform: rotate(180deg) !important;
                  }
                }

                .menu-container {
                  max-height: 50rem !important;
                }
              }

              .menu-head {
                display: flex;
                align-items: center;
                gap: $space-2;
                cursor: pointer;
                color: rgba($text-white, 0.6);
                // margin-bottom: 1rem;

                @include responsive(normal) {
                  color: unset;
                }

                .icon-down {
                  transition: 0.3s all;
                  display: none;

                  @include responsive(normal) {
                    display: block;
                  }
                }
              }

              .menu-container-wrapper {
                width: 100%;

                @include responsive(normal) {
                  position: absolute;
                  top: 180%;
                  left: 0;
                }

                @include responsive(normal) {
                  top: 130%;
                }

                .menu-container {
                  width: 100%;
                  max-height: 50rem;
                  overflow: hidden;
                  transition: 0.3s all;
                  border-radius: $radius-sm;

                  @include responsive(normal) {
                    max-height: 0;
                    min-width: 19.2rem;
                    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
                  }

                  &--products {
                    @include responsive(tablet) {
                      min-width: 28rem;
                    }
                  }

                  .menu-items {
                    width: 100%;
                    border-radius: $radius-sm;
                    padding: 1px;

                    @include responsive(normal) {
                      background-color: $background-white;
                    }

                    @include responsive(normal) {
                    }

                    .menu-item {
                      display: flex;
                      align-items: center;
                      gap: $space-2;
                      cursor: pointer;
                      transition: 0.3s all;
                      border-radius: $radius-sm;
                      padding: $space-4 0;
                      color: $text-white;
                      white-space: nowrap;

                      @include responsive(normal) {
                        padding: $space-4 $space-5;
                        color: unset;
                        // }

                        // @include responsive(normal) {
                        &:hover {
                          background-color: $background-white-hover;
                          color: $text;
                        }

                        &--active {
                          cursor: default;
                          pointer-events: none;
                          color: $text-primary !important;
                        }
                      }

                      .icon-item {
                        font-size: 2.4rem;
                        display: none;
                        color: $text-primary;

                        @include responsive(normal) {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }

            .btn-logo {
              display: none;

              @include responsive(normal) {
                display: block;
              }
            }
          }

          .list-btn-wrapper {
            @include responsive(normal) {
              display: none;
            }

            .title-btn {
              margin-bottom: $space-3;
              color: rgba($text-white, 0.6);
            }

            .list-btn {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: $space-3;
              padding-bottom: $space-5;

              .btn-custom {
                width: 100%;

                .button {
                  width: 100%;
                  border-radius: $radius-sm;
                }
              }
            }
          }
        }
      }
    }
  }
}
