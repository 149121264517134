@import "styles/main.scss";

.homeScreen {
  padding-top: $height-header + 7.2rem;
  overflow-x: hidden;

  :global {
    .banner-home {
      @include container;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 1;

      .banner-title {
        text-align: center;

        @include responsive(tablet) {
          width: 80%;
        }
      }

      .banner-description {
        text-align: center;
        margin: $space-6 auto $space-10;
        color: rgba($text, 0.7);
        line-height: 1.5;

        @include responsive(tablet) {
          width: 90%;
        }

        @include responsive(small) {
          width: 70%;
        }
      }

      .button-action {
        display: flex;
        justify-content: center;
      }
    }

    .box-image {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 0;
      margin-bottom: 10rem;

      &::after {
        content: "";
        position: absolute;
        bottom: -10rem;
        left: 0;
        width: 100%;
        height: 10rem;
        z-index: -1;
        // background: #000;
        background: linear-gradient(to top, $background-pink 30%, rgba($background-pink, 0));
      }

      @include responsive(normal) {
        margin-bottom: 20rem;

        &::after {
          display: none;
        }
      }

      &--img-2 {
        @include container;
        justify-content: unset;
        align-items: center;
        margin-top: 20rem !important;
        margin-bottom: $space-12 !important;
        position: relative;
        z-index: 0;

        &::after {
          display: none;
        }

        @include responsive(tablet) {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          height: 80rem;
        }

        @include responsive(small) {
          margin-bottom: 10rem !important;
        }

        @include responsive(normal) {
          margin-bottom: 25rem !important;
        }

        @include responsive(screen2k) {
          margin-bottom: 10rem !important;
        }
      }

      .mobile-img-banner {
        margin-top: $space-16;
        width: 70%;

        @include responsive(tablet) {
          width: 60%;
        }

        @include responsive(small) {
          width: 40rem;
        }

        .image-wallet {
          width: 100%;
        }
      }

      .box-mark {
        position: absolute;
        right: 0%;
        top: 15rem;
        z-index: -1;

        @include responsive(tablet) {
          right: 3%;
        }

        @include responsive(small) {
          right: 15%;
        }

        @include responsive(screen2k) {
          right: 30%;
        }

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -45rem;
          transform: translateX(-50%);
          width: 1px;
          height: 180rem;
          background-color: #b6b4e8;
          z-index: -1;

          @include responsive(tablet) {
            height: 200rem;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: calc(50% - 1px);
          transform: translate(-50%, -50%);
          width: 200vw;
          height: 1px;
          background-color: #b6b4e8;
          z-index: -1;
        }

        .brand-mark {
          width: 100%;
          width: 6.8rem;

          @include responsive(tablet) {
            width: 15rem;
          }
        }
      }

      .image-text {
        position: absolute;
        z-index: -1;
        width: 100%;
        bottom: -7rem;
        left: 50%;
        transform: translateX(-50%);

        @include responsive(tablet) {
          width: 100%;
          bottom: -10rem;
        }

        @include responsive(small) {
          bottom: -15rem;
        }

        @include responsive(normal) {
          bottom: -20rem;
        }

        @include responsive(mac-13) {
          @include container;
        }
      }

      .box-wrapper {
        @include container;

        .text-title {
          width: 100%;
          text-align: center;

          @include responsive(tablet) {
            text-align: unset;
          }

          @include responsive(small) {
            width: 1400px;
          }

          h1 {
            color: $text-primary;
            // width: 80%;
            margin: $space-2 auto 0;

            @include responsive(tablet) {
              margin: unset;
              width: 50%;
            }
          }
        }
      }

      .img-wallet-2 {
        z-index: 1;
        display: none;

        @include responsive(tablet) {
          display: block;
          position: absolute;
          top: 10rem;
          left: 94%;
          width: 100rem;
          transform: translateX(var(--progress));
        }

        @include responsive(tablet-2) {
          left: 86%;
        }

        @include responsive(small) {
          top: 10rem;
          left: 100%;
          width: 130rem;
        }

        @include responsive(normal) {
          // right: -27rem;
          left: 73%;
        }

        @include responsive(screen2k) {
          top: 15rem;
          // right: 2rem;
          left: 63%;
          width: 100rem;
        }

        .img-wallet-icon {
          width: 100%;
        }
      }
    }

    .ramper-app {
      padding-top: 15rem !important;
      // padding-bottom: 15rem !important;
      display: flex;
      justify-content: center;
      display: none;
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        top: -15rem;
        right: 50%;
        transform: translate(50%);
        width: 100vw;
        height: 120%;
        z-index: -1;
        background: linear-gradient(to top, $background-pink 80%, rgba($background-pink, 0));

        // @include responsive(screen2k) {
        //   top: -20rem;
        // }
      }

      @include responsive(tablet) {
        display: flex;
      }

      @include responsive(normal) {
        @include container;
        // padding-top: 0rem !important;
      }

      .app-screen {
        width: 120%;

        @include responsive(small) {
          width: 95%;
        }
      }
    }

    .mobile-screen {
      gap: $space-6;
      @include container;
      position: relative;
      z-index: 0;
      flex-direction: column;
      align-items: center;
      display: none;

      @include responsive(tablet) {
        display: flex;
        justify-content: center;
        margin-bottom: $space-12;
      }

      @include responsive(small) {
        display: grid;
        gap: 0;
        grid-template-columns: repeat(2, 1fr);
      }

      .mobile-app-wrapper {
        height: 100%;
        position: relative;
        width: unset;

        @include responsive(small) {
          width: 100%;
        }

        .mobile-app {
          position: relative;
          top: 0;
          width: 40rem;
          overflow: hidden;
          z-index: 0;

          @include responsive(small) {
            // left: $space-16;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
          }

          .img-mobile {
            width: 100%;

            @include responsive(small) {
              width: 100%;
            }
          }

          .img-cam {
            position: absolute;
            z-index: 3;
            width: 25%;
            top: 3rem;
            left: 50%;
            transform: translateX(-50%);
            // display: none;
          }

          .mobile-app-screen {
            width: 95%;
            height: 97%;
            background-color: $background-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            border-radius: 5.6rem;
            overflow: hidden;

            &--dark-mode {
              background-color: $background-black;
            }
          }

          .screen-app {
            width: 100% !important;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .circle {
        position: absolute;
        width: 10rem;
        top: 25rem;
        height: 10rem;
        border-radius: 50%;
        border: 0.5px solid rgba(255, 255, 255, 0.2);
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
        display: none;

        @include responsive(tablet) {
          display: block;
          top: 40rem;
          left: 45%;
        }

        @include responsive(small) {
          top: 25rem;
          left: 23.33%;
        }

        &--delay-1 {
          animation: waves 4s linear infinite;
        }

        &--delay-2 {
          animation: waves 4s linear 1s infinite;
        }

        &--delay-3 {
          animation: waves 4s linear 2s infinite;
        }
      }

      .content-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 0;

        @include responsive(small) {
          // width: 100%;
          height: 60rem;
          justify-content: center;
        }

        @include responsive(normal) {
          height: 60rem;
        }

        .mode-box {
          display: flex;
          justify-content: center;
          margin-bottom: $space-10;

          .switch-mode {
            transform: scale(1.2);
          }

          @include responsive(normal) {
            display: none;
          }
        }

        .content-text-wrapper {
          transition: 0.3s all;
          opacity: 0;
          display: flex;
          flex-direction: column;
          margin-bottom: $space-10;

          @include responsive(small) {
            height: 30rem;
            margin-bottom: 0;
            // justify-content: space-between;
          }

          &--show {
            opacity: 1;
          }

          h3,
          .text-description {
            text-align: center;

            @include responsive(small) {
              text-align: unset;
            }
          }

          h3 {
            margin-bottom: $space-6;
          }

          .text-description {
            color: $text-grey;
          }
        }

        .list-step {
          bottom: 4rem;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 9.6rem;

          @include responsive(small) {
            justify-content: space-between;
            position: absolute;
            padding-bottom: 0;
          }

          .steps-container {
            display: flex;
            align-items: center;
            gap: $space-1;

            .step-wrapper {
              margin-right: $space-1;
              display: flex;
              align-items: center;
              gap: $space-1;

              &:last-child {
                .line-space--active {
                  width: 0;
                }
              }

              .step-item {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background-color: rgba($text-white, 0.4);
                font-size: 1.7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: 0.3s all;

                @include responsive(normal) {
                  &:hover {
                    background-color: rgba($text-white, 1);
                  }
                }

                &--active {
                  background-color: #6633ff;
                  color: $text-white;

                  @include responsive(normal) {
                    &:hover {
                      background-color: rgba($text-white, 1);
                      color: $text;
                    }
                  }
                }
              }

              .line-space {
                width: 0;
                height: 1px;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.7) 0.51%, rgba(255, 255, 255, 0) 100%);
                transition: 0.3s all;

                &--active {
                  width: 7rem;
                }
              }
            }
          }
        }
      }
    }

    .mobile-screen-mobile {
      @include container;

      @include responsive(tablet) {
        display: none;
      }

      .item-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-10;
        margin-bottom: $space-20;

        &:last-child {
          margin-bottom: $space-12;
        }

        .img-mobile {
          width: 80%;
        }

        .text-body {
          .title-text,
          .description-text {
            text-align: center;
            line-height: 1.5;
          }

          .title-text {
            margin-bottom: $space-6;
          }

          .description-text {
            color: rgba($text, 0.7);
          }
        }
      }
    }

    .block-text {
      background-color: $background-black;
      padding: 9.6rem 0;

      @include responsive(tablet) {
        min-height: 50rem;
      }

      @include responsive(small) {
        // height: 100rem;
        padding: 30rem 0 12rem;
        gap: 0;
        flex-direction: row;
      }

      @include responsive(normal) {
        // height: 90rem;
      }

      .block-text-container {
        @include container;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: $space-52;

        @include responsive(normal) {
          align-items: center;
          gap: 0;
          flex-direction: row;
        }

        .item-block {
          h2 {
            color: #c3f500;
            white-space: nowrap;
          }

          &--title {
            display: none;

            @include responsive(tablet) {
              display: block;
            }
          }

          &--name-chain {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;

            @include responsive(normal) {
              width: 50%;
            }

            .item-icon {
              width: 30%;
              display: flex;
              justify-content: flex-end;
              padding-right: $space-52;
              position: relative;
              display: none;

              @include responsive(tablet) {
                display: flex;
              }

              @include responsive(normal) {
                width: 60%;
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: calc(100% - 3.4rem - 6rem);
                height: 1px;
                background-color: $border;
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: -2rem;
                transform: translateY(-50%);
                width: 25%;
                height: 1px;
                background-color: $border;

                @include responsive(normal) {
                  width: 10%;
                  right: 1rem;
                }
              }

              .icon-arrow {
                color: $text-white;
                font-size: 3.4rem;
              }
            }

            .list-text-wrapper {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              @include responsive(tablet) {
                padding-left: $space-5;
                width: 40%;
              }

              .list-text {
                height: 50rem;
                width: 100%;
                position: relative;
                overflow: hidden;

                &::after {
                  content: "";
                  position: absolute;
                  top: 0%;
                  left: 0;
                  width: 100%;
                  background: linear-gradient(180deg, $background-black 10%, rgba($background-black, 0) 100%);
                  z-index: 1;
                  height: 45%;

                  // @include responsive(tablet) {
                  //   top: -2%;
                  //   // height: 40%;
                  // }

                  // @include responsive(small) {
                  //   top: 0%;
                  // }
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: 55%;
                  left: 0;
                  width: 100%;
                  background: linear-gradient(
                    to top,
                    $background-black 10%,
                    rgba($background-black, 0.5) 90%,
                    rgba($background-black, 0) 100%
                  );
                  z-index: 1;
                  height: 45%;

                  // @include responsive(tablet) {
                  //   // top: 62%;
                  //   // height: 40%;
                  // }

                  // @include responsive(small) {
                  //   top: 80%;
                  // }
                }

                .marquee-text {
                  height: 150%;
                  // width: 200%;
                  margin-top: $space-5;

                  @include responsive(tablet) {
                    height: 90%;
                    width: 200%;
                  }

                  .rfm-marquee {
                    align-items: flex-start;

                    .rfm-initial-child-container {
                      .rfm-child {
                        width: 100%;
                        font-size: 4rem;
                        color: $text-white;
                      }
                    }
                  }
                }

                .react-final-marquee {
                  .react-final-marquee-wrapper {
                    width: 100%;

                    .react-final-marquee-item {
                      width: 100%;
                    }
                  }
                }

                .text-content {
                  font-size: 4rem;
                  color: $text-white;
                  width: 100%;
                  // width: 7rem;
                  white-space: nowrap;
                  padding-top: $space-5;
                  text-align: center;

                  @include responsive(normal) {
                    text-align: unset;
                  }
                }
              }

              .item-text {
                h3 {
                  font-size: 4rem;
                  text-align: center;

                  @include responsive(tablet) {
                    text-align: unset;
                  }
                }
              }
            }

            .line-mobile {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 30%;
              height: 1px;
              background-color: $border;
              z-index: 2;
              display: none;

              @include responsive(tablet) {
                display: block;
              }

              @include responsive(normal) {
                display: none;
              }
            }
          }
        }

        .title-mobile {
          color: #c3f500;
          text-align: center;

          @include responsive(tablet) {
            display: none;
          }
        }
      }
    }

    .trusted-wrapper {
      height: 100rem;
      background-color: $background-white;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      padding-bottom: 15rem;

      @include responsive(tablet) {
        height: 110rem;
        padding-bottom: 10rem;
      }

      @include responsive(small) {
        padding-bottom: 15rem;
      }

      @include responsive(normal) {
        height: 90rem;
      }

      .trusted-container {
        @include container;
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: column;
        gap: $space-52 + 0.4rem;

        @include responsive(normal) {
          flex-direction: row;
        }

        .box-direction {
          display: flex;
          gap: $space-10;
          margin-top: 1rem;

          @include responsive(normal) {
            align-items: center;
            margin-top: 5.6rem;
          }

          &--no-mobile {
            display: none;

            @include responsive(tablet) {
              display: flex;
            }
          }

          &--mobile {
            justify-content: center;

            @include responsive(tablet) {
              display: none;
            }
          }

          .icon-arrow {
            font-size: 4rem;
            cursor: pointer;
            transition: 0.3s all;

            @include responsive(normal) {
              &:hover {
                transform: scale(1.2);
              }
            }

            &--left-disable,
            &--right-disable {
              opacity: 0.4;
              pointer-events: none;
            }
          }
        }

        .trusted-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;

          @include responsive(tablet) {
            justify-content: unset;
            flex-direction: column;
            gap: $space-6;
            // justify-content: space-between;
          }

          @include responsive(normal) {
            gap: 0;
            flex-direction: column;
            width: 90%;
          }

          h2 {
            width: 100%;
            text-align: center;

            @include responsive(tablet) {
              width: 60rem;
              text-align: unset;
            }
          }
        }

        .trusted-list-wrapper {
          width: 100%;
          position: relative;
          z-index: 0;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }

          ::-webkit-scrollbar {
            display: none;
          }

          @include responsive(normal) {
            width: calc(760px + var(--width-space));
          }

          // @include responsive(normal) {
          //   width: calc(960px + var(--width-space));
          // }

          .trusted-list-container {
            overflow-x: scroll;
            display: flex;
            gap: $space-6;

            @include responsive(normal) {
              padding-right: $space-6;
            }

            .item-trusted {
              .item-content {
                width: 32rem;
                height: 45rem;
                padding: $space-8 $space-6;
                border-radius: $radius-sm;
                border: 1px solid #ccc;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                line-height: 1.6;

                @include responsive(tablet) {
                  padding: $space-10;
                  width: 48rem;
                  height: 48rem;
                }

                .author-box {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  position: relative;
                  z-index: 2;

                  .author-info {
                    display: flex;
                    align-items: center;
                    gap: $space-3;

                    .author-info__img {
                      width: 4.8rem;
                      height: 4.8rem;
                      border-radius: 50%;
                      overflow: hidden;

                      .icon-author {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }

                    .text-info {
                      .text-position {
                        color: $text-grey;
                      }
                    }
                  }

                  .icon-social {
                    font-size: 2.4rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .future-screen {
      @include container;
      height: 90rem;
      position: relative;
      z-index: 0;
      display: flex;
      align-items: flex-end;
      padding-bottom: $space-12 !important;

      @include responsive(tablet) {
        padding-bottom: 12rem !important;
        height: 130rem;
      }

      @include responsive(normal) {
        padding-bottom: 13rem !important;
        height: 115rem;
      }

      .device-screen {
        position: absolute;

        &--tablet {
          width: 150%;
          top: -15rem;
          right: 2.4rem;

          @include responsive(tablet) {
            width: 120%;
            right: 2.4rem;
          }

          @include responsive(small) {
            right: 6.4rem;
            width: 80%;
          }
        }

        &--mobile {
          top: 0rem;
          width: 18rem;
          left: $space-5;

          @include responsive(tablet) {
            width: 30rem;
          }

          @include responsive(small) {
            left: 6.4rem;
            top: 2rem;
          }

          @include responsive(normal) {
            width: 33rem;
          }

          .device-img {
            width: 100%;
          }
        }
      }

      .body-content {
        display: flex;
        justify-content: space-between;
        gap: 9.6rem;
        flex-direction: column;

        @include responsive(normal) {
          flex-direction: row;
        }

        h2,
        .app-future {
          width: 100%;

          @include responsive(tablet) {
            width: 80%;
          }

          @include responsive(normal) {
            width: 50%;
          }
        }

        h2 {
          span {
            color: $text-primary;
          }
        }
      }
    }
  }
}
