@import "styles/main.scss";

.ramperPageWrapper {
  overflow-x: hidden;
  // background-color: $background-white;

  :global {
    .ramper-container {
      .section-title-wrapper {
        padding-top: $height-header !important;
        background-color: $background-pink;

        .section-title {
          @include container;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-top: $space-12 !important;
          padding-bottom: 11rem !important;

          @include responsive(tablet) {
            width: 90%;
            padding-top: $space-20 !important;
            padding-bottom: $space-20 !important;
          }

          @include responsive(normal) {
            padding-top: 12rem !important;
            padding-bottom: 10rem !important;
          }

          h2 {
            text-align: center;
          }

          .text-description {
            margin: $space-4 0 $space-10;
            text-align: center;
            color: rgba($text, 0.7);
            line-height: 1.5;

            @include responsive(tablet) {
              width: 90%;
            }

            @include responsive(small) {
              width: 65%;
            }
          }

          .btn-action {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: $space-3;

            @include responsive(tablet) {
              flex-direction: row;
            }

            .btn-custom {
              &--nft-btn {
                .button {
                  min-width: 17rem !important;
                }
              }

              .button {
                min-width: 20rem;
              }
            }
          }
        }
      }

      .section-content-wrapper {
        @include container;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .section-content {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: $space-12 0;
          padding-top: 0;

          @include responsive(tablet) {
            padding: $space-10 0;
            padding-top: 0;
          }

          .title-content {
            text-align: center;
            margin-bottom: 10rem;
          }

          .content-body {
            padding: 0 $space-3;
            margin: 0 auto;

            @include responsive(tablet) {
              padding: 0 $space-6;
            }

            @include responsive(normal) {
              padding: 0;
            }

            .content-item {
              background-color: #f0ebff;
              border-radius: $radius-sm1;
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-bottom: 12rem;
              overflow: hidden;
              margin-bottom: $space-10;

              @include responsive(normal) {
                gap: $space-10;
                flex-direction: row;

                &--reverse {
                  flex-direction: row-reverse;
                }
              }

              .item-content {
                width: 50%;
                padding-top: 7.2rem;
                padding-right: 6.4rem;

                &--img {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-right: 0;
                  width: 100%;
                }

                &--img-nft {
                  padding-top: 0;
                }

                &--text {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  padding: $space-8 $space-6;
                  background-color: $background-white;

                  @include responsive(tablet) {
                    padding: $space-12;
                  }

                  @include responsive(normal) {
                    background-color: transparent;
                    // width: calc(50% - 4rem);
                    // width: 70%;
                  }

                  // @include responsive(normal) {
                  // width: calc(50% - 4rem);
                  // width: 70%;
                  // }
                }

                .icon-item {
                  width: 100%;

                  @include responsive(tablet) {
                    width: 70%;
                  }
                }

                .item-title {
                  margin-bottom: $space-6;
                }

                .item-text {
                  .text {
                    display: flex;
                    align-items: center;
                    gap: $space-3;
                    margin-bottom: $space-4;
                    line-height: 1.5;
                    color: $text-grey;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    .dot {
                      width: 5px;
                      height: 5px;
                      border-radius: 50%;
                      background-color: $background-black;
                    }
                  }
                }
              }
            }

            .content-item-ipad {
              display: flex;
              flex-direction: column;
              width: 100%;
              border-radius: $radius-sm1;
              overflow: hidden;

              .item-content-ipad {
                width: 100%;
                display: flex;
                justify-content: center;
                background-color: #f0ebff;
                padding-top: 7.2rem;

                &--text {
                  background-color: $background-white;
                  padding: $space-8 $space-6;
                  gap: $space-8;
                  flex-direction: column;
                  justify-content: center;

                  @include responsive(tablet) {
                    padding: $space-12 $space-16 $space-16;
                  }

                  @include responsive(normal) {
                    flex-direction: row;
                  }

                  .item-text {
                    width: calc(50% - 3.2rem);
                  }
                }

                &--img-sdk {
                  padding-top: 0;
                }

                .icon-item {
                  width: 150%;
                  margin-left: 66%;

                  @include responsive(tablet) {
                    width: 130%;
                    margin-left: 46%;
                  }

                  @include responsive(normal) {
                    width: 70%;
                    margin-left: 0;
                  }

                  &--desktop {
                    display: none;

                    @include responsive(tablet) {
                      display: block;
                    }
                  }

                  &--mobile {
                    @include responsive(tablet) {
                      display: none;
                    }
                  }

                  &--img-sdk {
                    width: 100%;
                    margin-left: 0 !important;
                  }
                }

                .item-text {
                  width: 100%;

                  //   @include responsive(normal) {
                  //   width: 100%;
                  // }

                  .text-description {
                    margin-bottom: $space-4;
                  }

                  .text {
                    display: flex;
                    align-items: center;
                    gap: $space-3;
                    margin-bottom: $space-2;
                    line-height: 1.5;
                    color: $text-grey;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    .dot {
                      width: 5px;
                      height: 5px;
                      border-radius: 50%;
                      background-color: $text-grey;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .section-footer-wrapper {
        color: $text-white;
        position: relative;
        z-index: 0;
        padding: 0;

        @include responsive(tablet) {
          padding: 0 $space-6;
        }

        @include responsive(normal) {
          padding: 0;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100vw;
          height: 10rem;
          z-index: -2;
          background-color: $background-white;
        }

        // @include responsive(tablet) {
        //   padding: 7.2rem 0;
        // }

        .section-footer {
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: $btn-primary;
          @include container;
          padding-top: 7.2rem !important;
          padding-bottom: 7.2rem !important;

          @include responsive(tablet) {
            border-radius: $radius-sm1;
          }

          .text-description {
            text-align: center;
            line-height: 1.5;
            margin: $space-4 0 $space-10;
            color: rgba($text-white, 0.8);
          }

          .ft-title {
            text-align: center;

            &--nft {
              margin-bottom: $space-8;
            }
          }

          .icon-logo-footer {
            color: $text-primary;
          }
        }
      }
    }
  }
}
