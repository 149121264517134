@import "styles/main.scss";

.modal-get-wrapper {
  width: 100vw;
  background-color: $background-pink;
  padding: $space-52 $space-8;
  overflow: hidden;

  @include responsive(tablet) {
    // height: 110vh;
    padding: $space-52 + 0.4rem;
  }

  @include responsive(normal) {
    height: 100vh;
    padding: $space-20 $space-24;
  }

  .modal-get-head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: $space-8 0 $space-4;

    @include responsive(tablet) {
      margin-bottom: $space-8;
    }

    .icon-close {
      font-size: 2.4rem;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .modal-get-body {
    // @include container;
    position: relative;
    z-index: 0;
    // height: 100%;
    // height: calc(100vh - 8rem - 18rem);
    display: grid;
    grid-template-columns: 1fr;
    gap: $space-12;
    margin-top: $space-8;

    @include responsive(tablet) {
      gap: $space-16;
    }

    @include responsive(normal) {
      height: calc(100vh - 18rem);
    }

    .body-text {
      @include responsive(normal) {
        width: 65rem;
      }

      h3 {
        width: 70%;
        margin-bottom: $space-6;

        @include responsive(tablet) {
          width: 100%;
          font-size: 5.2rem;
          margin-bottom: $space-52 + 0.4rem;
        }

        span {
          color: $text-primary;
        }
      }
    }

    .body-app {
      @include responsive(normal) {
        width: 80%;
      }
    }

    .img-app {
      z-index: -1;
      position: relative;
      display: flex;
      justify-content: flex-end;
      // height: auto;

      @include responsive(normal) {
        width: 60%;
        position: absolute;
        top: 50%;
        right: calc(-6% - 9.6rem);
        transform: translateY(-50%);
      }

      @include responsive(mac-16) {
        width: 65%;
      }

      .app-screen {
        &--tablet {
          width: 150%;

          @include responsive(tablet) {
            width: 95%;
            // margin-bottom: 5rem;
          }
        }

        &--mobile {
          position: absolute;
          width: 45%;
          z-index: 1;
          bottom: -3rem;
          left: 0;

          @include responsive(tablet) {
            width: 28%;
          }

          @include responsive(normal) {
            left: -5rem;
          }

          @include responsive(mac-13) {
            bottom: -5rem;
          }
        }
      }
    }
  }
}
