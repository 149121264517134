@import "styles/main.scss";

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  transition: 0.5s all;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;

  // @include responsive(normal) {
  //   top: -100%;
  // }

  &--show {
    // top: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .modal-container {
    width: 100%;
  }
}
