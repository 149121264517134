@import "styles/main.scss";

.myButton {
  width: max-content;

  :global {
    .button {
      outline: none;
      padding: $space-3 $space-5;
      border-radius: $radius;
      min-width: 10.2rem;
      min-height: 5rem;
      background-color: $btn-primary;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $space-3;
      transition: 0.3s all;
      cursor: pointer;
      color: $text-white;

      @include responsive(tablet) {
        padding: $space-4 $space-8;
        border-radius: $radius;

        &:hover {
          background-color: $btn-hover !important;
        }
      }

      span {
        font-size: 2.4rem;
      }

      img {
        width: 2.4rem;
      }

      &--small {
        padding: $space-3 $space-6;
        font-size: 1.7rem;

        span {
          font-size: 1.6rem;
        }
      }

      &--secondary {
        background-color: $background-white;
        color: $text;

        @include responsive(tablet) {
          &:hover {
            background-color: $btn-hover !important;
            color: $text-white;
          }
        }
      }

      &--border {
        background-color: transparent;
        color: $text;
        border: 1px solid $background-black;
        padding: calc($space-3 - 1px) calc($space-5 - 1px);

        @include responsive(tablet) {
          padding: calc($space-4 - 1px) calc($space-8 - 1px);

          &:hover {
            background-color: $btn-hover !important;
            color: $text-white;
          }
        }
      }

      &:active {
        background-color: $btn-second;
      }
    }
  }
}
