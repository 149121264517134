h1 {
  font-size: 4.8rem;
  margin: 0;

  @include responsive(tablet) {
    font-size: 6.4rem;
  }

  @include responsive(small) {
    font-size: 8rem;
  }
}

h2 {
  font-size: 3.6rem;
  margin: 0;

  @include responsive(tablet) {
    font-size: 6.4rem;
  }
}

h3 {
  font-size: 3.2rem;
  margin: 0;

  @include responsive(tablet) {
    font-size: 4rem;
  }

  @include responsive(normal) {
    font-size: 5.2rem;
  }
}

h4 {
  font-size: 2.4rem;
  margin: 0;

  @include responsive(small) {
    font-size: 4rem;
  }
}

h5 {
  font-size: 2.4rem;
  margin: 0;

  @include responsive(small) {
    font-size: 3.2rem;
  }
}

h6 {
  font-size: 2.4rem;
  margin: 0;

  @include responsive(small) {
  }
}

.text-lg {
  font-size: 1.8rem;

  @include responsive(tablet) {
    font-size: 2.4rem;
  }
}

.text-base {
  font-size: 2rem;

  @include responsive(small) {
  }
}

.text-sm {
  font-size: 1.8rem;

  @include responsive(small) {
  }
}

.text-xs {
  font-size: 1.5rem;

  @include responsive(small) {
    font-size: 1.7rem;
  }
}
