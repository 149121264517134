.slide-block {
  width: 100%;

  .slick-list {
    .slick-track {
      .slick-slide {
        font-size: 4rem;
      }

      .slick-center {
        font-size: 4.8rem;
      }
    }
  }
}

.slide-trusted {
  width: 100%;

  .slick-list {
    // height: 45rem;
    width: 100%;

    .slick-track {
      width: 100%;

      .slick-slide {
        // width: 50rem !important;
      }
    }
  }
}

.slide-screen {
  width: 100%;
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide {
        div {
          height: 100%;
        }
      }
    }
  }
}
