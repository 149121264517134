@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/font-family/PlusJakartaSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/font-family/PlusJakartaSans-SemiBold.ttf");
  font-weight: bold;
}
