@import "styles/main.scss";

.footerWrapper {
  padding: $space-16 0 10rem;
  background-color: $background-white;
  overflow: hidden;

  @include responsive(tablet) {
    padding: $space-16 0 15rem;
  }

  @include responsive(small) {
    padding: $space-16 0 20rem;
  }

  :global {
    .footer-container {
      @include container-footer;
      display: grid;
      grid-template-columns: 1fr;
      gap: 9.6rem;
      position: relative;
      z-index: 0;

      @include responsive(small) {
        grid-template-columns: repeat(2, 1fr);
      }

      .footer-item {
        width: 100%;

        .ft-description {
          margin: $space-2 0 $space-8;
          color: $text-grey;
          line-height: 1.6;
        }

        .input-footer {
          display: flex;
          flex-direction: column;
          gap: $space-5;
          width: 100%;

          @include responsive(tablet) {
            align-items: center;
            flex-direction: row;
          }

          .input-content {
            padding: 1rem $space-4;
            border-radius: $radius-sm;
            border: 1px solid $border;
            width: 100%;
            font-size: 1.8rem;
            display: none;

            &::placeholder {
              color: $text-grey-2;
            }
          }

          .btn-footer {
            width: 100%;

            .button {
              min-width: 100%;
            }

            @include responsive(tablet) {
              width: max-content;
            }
          }
        }
      }

      .footer-right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include responsive(normal) {
          // align-items: flex-end;
          flex-direction: row;
        }

        @include responsive(small) {
          height: 22rem;
          flex-direction: column;
        }

        .menu-content {
          display: flex;
          flex-direction: column;
          gap: $space-6;
          font-size: 1.7rem;
          margin-bottom: $space-12;

          @include responsive(tablet) {
            gap: $space-52;
            flex-direction: row;
            margin-bottom: 0;
          }

          @include responsive(small) {
            // align-items: flex-end;
            flex-direction: row;
          }

          .item-menu {
            display: flex;
            flex-direction: column;

            .item-menu__title {
              color: $text-primary;
              margin-bottom: $space-5;
            }

            .item-menu__list {
              display: flex;
              flex-direction: column;
              gap: $space-5;

              .menu {
                cursor: pointer;
                transition: 0.3s all;

                &:last-child {
                  margin-bottom: 0;
                }

                // &--active {
                //   color: $text-primary !important;
                //   cursor: default;
                //   pointer-events: none;
                // }

                @include responsive(normal) {
                  &:hover {
                    color: $btn-hover;
                  }
                }
              }
            }
          }
        }
      }

      .img-text {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -13.5rem;

        @include responsive(tablet) {
          bottom: -35rem;
        }

        @include responsive(tablet-2) {
          bottom: -25rem;
        }

        @include responsive(small) {
          bottom: -35rem;
        }

        @include responsive(normal) {
          bottom: -40rem;
        }

        @include responsive(mac-13) {
          width: 1440px;
        }
      }

      .footer-social {
        margin-top: $space-52;

        &--mobile {
          @include responsive(normal) {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include responsive(normal) {
            display: block;
          }
        }

        .footer-text {
          margin-top: $space-6;
          color: $text-grey-2;
          text-align: center;

          @include responsive(tablet) {
            text-align: left;
          }
        }
      }

      .list-social {
        font-size: 2.4rem;

        @include responsive(tablet) {
          justify-content: unset;
          width: 50%;
        }

        &--desktop {
          display: none;

          @include responsive(normal) {
            display: block;
          }
        }

        .social-title {
          color: $text-primary;
          font-size: 1.7rem;
          margin-bottom: $space-4;
        }

        .social-list {
          display: flex;
          align-items: center;
          gap: $space-10;
          color: $text-grey-2;
          justify-content: space-between;
        }

        .icon-social {
          cursor: pointer;
          transition: 0.3s all;

          @include responsive(normal) {
            &:hover {
              color: $btn-hover;
            }
          }
        }
      }
    }
  }
}
