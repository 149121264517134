$text: #0a0a0a;
$text-second: #333333;
$text-grey: #4d4d4d;
$text-grey-2: #bcbcbc;
$text-grey-3: #eeedec;
$text-white: #ffffff;
$text-primary: #6633ff;

$btn-primary: #6633ff;
$btn-hover: #5f20f0;
$btn-second: #6a2cf6;

$background-white: #ffffff;
$background-white-2: #f2f2f2;
$background-white-hover: #f1f0ff;
$background-black: #0a0a0a;
$background-pink: #c9c7fb;

$border: #404040;
