@import "styles/main.scss";

.switchWrapper {
  :global {
    // switch
    .switch-container {
      display: flex;
      align-items: center;
      gap: $space-2;
    }

    .my-switch {
      position: relative;
      display: inline-block;
      width: 8.4rem;
      height: 4rem;
      margin-bottom: 0;
      z-index: 0;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($text-white, 0.2);
        transition: 0.4s;
        border-radius: 99px;
        border: 1px solid rgba($text-white, 0.4);

        &::before {
          position: absolute;
          content: "";
          height: 3.4rem;
          width: 4rem;
          border-radius: 100px;
          left: 2px;
          bottom: 2px;
          background-color: rgba($text-white, 0.4);
          transition: 0.4s;
        }
      }

      &__border {
        .slider {
          border-radius: 20px;

          &::before {
            border-radius: 50%;
          }
        }
      }

      .icon-mode {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2.4rem;
        pointer-events: none;

        &--dark {
          right: 1rem;
        }

        &--light {
          left: 1rem;
        }
      }
    }

    .my-switch--checked-disbaled {
      .slider {
        background-color: #4c4020 !important;
      }
    }

    input:disabled + .slider {
      background-color: #575757;
      cursor: no-drop;
    }

    input:checked + .slider {
      // background-color: blue;
    }

    input:checked + .slider:before {
      transform: translateX(3.8rem);
    }
  }
}
