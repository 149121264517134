@import "./main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  // background: $background;
  // color: $text-color;
  // overflow-x: hidden;
  overflow-y: auto;
  // height: 100vh;
  background-color: $background-pink;
  scroll-behavior: smooth;
  font-family: "Plus Jakarta Sans";
  color: $text;
}

html {
  // min-height: 100%;
  // height: auto;
  // font-size: 56% !important;
  font-size: 10px;
  width: 100%;
  font-size: 10px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive(mac-13) {
    font-size: 62.5% !important;
  }
}

body {
  width: 100%;
  // overflow: hidden;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: unset;

  @include responsive(tablet) {
    font-size: 2rem;
  }
}

a {
  color: unset;
  text-decoration: none;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    // color: $second;
  }
}

.top-header {
  padding-top: 9.6rem;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}
