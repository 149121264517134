@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(10);
    opacity: 0;
  }
}
